@use "variables";

#about {
  // padding: 50px 0px;
  display: flex;

  .aboutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 50px;
    // max-width: 700px;
    padding: 100px 0px;
    margin: 0px auto;
  }

  .content {
    max-width: 600px;

    h1 {
      font-size: 32px;
      color: variables.$light001;
      margin-bottom: 30px;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;
      color: variables.$light006;

      .signifyText {
        // color: #00c2ff;
        // text-decoration: none;
        color: variables.$light002;
      }
    }
  }

  .skillsList {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    gap: 10px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        margin-left: 10px;
        font-size: 16px;
      }
    }
  }

  .overlay {
    opacity: 1;
    position: absolute;
    background-color: rgba($color: #00c2ff, $alpha: 0.22);
    mix-blend-mode: hard-light;
    height: 350px;
    width: 250px;
    border-radius: 8px;
    transition: all 0.2s ease;
    &:hover {
      background-color: rgba($color: #00c2ff, $alpha: 0);
    }
  }
  .aboutMeImage {
    height: 350px;
    width: 250px;
    border-radius: 8px;
    object-fit: cover;
    transition: all 0.2s ease;
  }
  .boxElement {
    position: absolute;
    z-index: -30;
    height: 350px;
    width: 250px;
    border-radius: 8px;
    border-radius: 8px;
    border: 1px solid variables.$light;
    transform: translate(10px, 10px);
    transition: all 0.1s ease;
  }

  .animateOverlay {
    transform: translate(-10px, -10px);
  }

  .translateElement {
    transform: translate(12px, 12px);
  }
  .translateImage {
    transform: translate(-10px, -10px);
  }
  // .target {
  //   height: 100px;
  //   width: 200px;
  //   background-color: cadetblue;
  // }
}

// @media (max-width: variables.$tabWidth) {
//   .imageFrame {
//     display: none;
//   }
// }

@media (min-width: variables.$tabWidth) {
  #about {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: variables.$tabWidth) {
  #about {
    justify-content: center;
    align-items: center;
    .aboutContainer {
      flex-direction: column;
    }
    .overlay {
      height: 300px;
      width: 300px;
    }
    .aboutMeImage {
      height: 300px;
      width: 300px;
    }
    .boxElement {
      height: 300px;
      width: 300px;
    }
    .imageFrame {
      margin-top: 50px;
    }
  }
}
